






































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class DoubleCheck51 extends Vue {

  @Prop({
    default: false,
  })
  private isOpen!: boolean;

  @Emit('onDoubleCheckAnswer')
  public doubleCheck(isOk: boolean) {
    return isOk;
  }
}
