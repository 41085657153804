




































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Emulator, EmulatorComponent, EmulatorVar } from '@/models/EmulatorModels';
import CMCEmulator from '@/components/cmc/CMCEmulator.vue';
import TestModeSwitch from '@/components/cmc/controls/TestModeSwitch.vue';
import AircraftType from '@/enums/user/aircraft';
import { fetchMasterEmulatorByID } from '@/utils/common';
import Aircraft51 from '@/components/area51/Aircraft51.vue';
import AlienUpload from '@/components/area51/AlienUpload.vue';

const globalUI = namespace('GlobalUIModule');
const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    CMCEmulator,
    TestModeSwitch,
    Aircraft51,
    AlienUpload,
  },
})
export default class Area51 extends Vue {

  get AircraftType() {
    return AircraftType;
  }

  public get isOn(): boolean {
    return this.isDemoMode;
  }

  public set isOn(value: boolean) {
    this.setDemoMode(value);
  }

  public isPreviewOpen: boolean = false;

  @globalUI.Getter
  public isDemoMode!: boolean;

  @globalUI.Mutation
  public setDemoMode!: (value: boolean) => void;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Getter
  public currentEmulatorState!: EmulatorVar[];

  @emulatorModule.Action
  public patchEmulator!: (emulator: Emulator) => Promise<void>;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  @emulatorModule.Action
  public chooseEmulator!: (emulator: Emulator) => void;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (emulatorPane: EmulatorComponent) => void;

  public get onOffLabel(): string {
    return this.isOn ? 'ON' : 'OFF';
  }

  public onOverlayClick(): void {
    this.isPreviewOpen = false;
  }

  private async onPreviewG450Click(): Promise<void> {
    this.isPreviewOpen = true;
    const emulator: Emulator = await fetchMasterEmulatorByID('g450-base');
    this.chooseEmulator(emulator);
    this.chooseCurrentEmulatorPane(emulator.panes!['1.0']);
  }

  private async onPreviewG550Click(): Promise<void> {
    this.isPreviewOpen = true;
    const emulator: Emulator = await fetchMasterEmulatorByID('g550-base');
    this.chooseEmulator(emulator);
    this.chooseCurrentEmulatorPane(emulator.panes!['1.0']);
  }

  private async onPreviewG650Click(): Promise<void> {
    this.isPreviewOpen = true;
    const emulator: Emulator = await fetchMasterEmulatorByID('g650-base');
    this.chooseEmulator(emulator);
    this.chooseCurrentEmulatorPane(emulator.panes!['1.0']);
  }

  private async onPreviewUFOClick(): Promise<void> {
    this.isPreviewOpen = true;
    const emulator: Emulator = await fetchMasterEmulatorByID('ufo-base');
    this.chooseEmulator(emulator);
    this.chooseCurrentEmulatorPane(emulator.panes!['1.0']);
  }
}
