








































/* tslint:disable:no-console */
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {Md5} from 'ts-md5';

@Component({})
export default class Auth51 extends Vue {
  public superUserPassword: string = '';

  public passwordRules = [
    (v: string) => !!v || 'Enter a password, helpless human',
  ];

  @Prop({
    default: false,
  })
  private isOpen!: boolean;

  @Emit('onClearanceArea51')
  private onCleared(success: boolean): boolean {
    return success;
  }

  private validatePassword(): void {
    const success: boolean = (Md5.hashStr(this.superUserPassword) === '011d18982e6c4a4f55ddca28be947c95');
    if (success) {
      // eslint-disable-next-line no-console
      console.info('You have been cleared to access Area 51.');
      this.onCleared(success);
    } else {
      // eslint-disable-next-line no-console
      console.error('Access Denied.');
    }
    this.superUserPassword = '';
  }

  private get hashedPwd(): string {
    return Md5.hashStr(this.superUserPassword) as string;
  }

}
