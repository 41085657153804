













































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CAEImage,ImagePayload } from '@/models/GlobalModels';
import { ImageType } from '@/enums/globalUI';
import { UserProfile } from '@/models/UserModels';

const mediaImageModule = namespace('ImageModule');
const userModule = namespace('UserModule');

@Component({})
export default class AlienUpload extends Vue {

  private get fileURL(): string {
    if (this.currentUserProfile) {
      return this.currentUserProfile.avatar!;
    } else {
      return '/img/features/anonymous.svg';
    }
  }

  @userModule.Getter
  public currentUserProfile!: UserProfile;

  @userModule.Getter
  public currentUserId!: string;

  @mediaImageModule.Getter
  public currentFile!: CAEImage | null;

  @mediaImageModule.Action
  public uploadImage!: (imagePayload: ImagePayload) => Promise<void>;

  private imageFile: any = null;

  private fileRules = [
    (value) => !value || value.size < 4000000 || '#Avatar size should be less than 2 MB!',
  ];

  public async uploadFile(): Promise<void> {
    if (this.imageFile !== null){
      const data = new FormData();
      data.append('file', this.imageFile);
      const imagePayload: ImagePayload = {
        id: '',
        data,
        type: ImageType.EMULATOR,
      };
      await this.uploadImage(imagePayload);
      this.imageFile = null;
    }
  }
}
