





































import { Component, Prop, Vue } from 'vue-property-decorator';
import AircraftType from '@/enums/user/aircraft';
import Auth51 from '@/components/area51/Auth51.vue';
import { Emulator } from '@/models/EmulatorModels';
import { fetchMasterEmulatorByID } from '@/utils/common';
import { namespace } from 'vuex-class';
import DoubleCheck51 from '@/components/area51/DoubleCheck51.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    Auth51,
    DoubleCheck51,
  },
})
export default class Aircraft51 extends Vue {
  public isSuperUserOpen: boolean = false;
  public isDoubleCheckOpen: boolean = false;
  public isSyncing: boolean = false;

  get AircraftType() {
    return AircraftType;
  }

  get jsonURL() {
    switch (this.type) {
      case AircraftType.G_650:
        return 'g650-base';
      case AircraftType.G_550:
        return 'g550-base';
      case AircraftType.G_450:
        return 'g450-base';
      case AircraftType.UFO:
        return 'ufo-base';
      default:
        return '';
    }
  }

  @emulatorModule.Action
  public createEmulator!: (emulator: Emulator) => Promise<void>;

  @Prop({
    required: true,
  })
  public type!: AircraftType;

  @Prop({
    default: false,
  })
  public isDoubleChecked!: boolean;

  private openVerificationProcess(): void {
    if (this.isDoubleChecked) {
      this.isDoubleCheckOpen = true;
    } else {
      this.isSuperUserOpen = true;
    }
  }

  private onDoubleCheckHandler(isOk: boolean): void {
    this.isDoubleCheckOpen = false;
    if (isOk) {
      this.isSuperUserOpen = true;
    }
  }

  private onClearanceHandler(success: boolean): void {
    if (success) {
      this.syncAircraft(this.jsonURL);
    }
    this.isSuperUserOpen = false;
  }

  private async syncAircraft(pathBase: string) {
    const emulator: Emulator = await fetchMasterEmulatorByID(pathBase);
    this.isSyncing = true;
    await this.createEmulator(emulator);
    this.isSyncing = false;
  }
}
